<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from 'vue'
// import moment from 'moment';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Detail Notulen Radisi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    onMounted(async () => {
      await router.isReady()
      //console.log('route.path', route.path)
    })

    return { route }
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Detail Notulen Radisi",
      items: [
        {
          text: "Monitoring",
          href: "/",
        },
        {
          text: "Notulen Rapat Direksi",
          href: "/monitoring/notulen",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      dataTable: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",

      series: [],
      chartOptions: {
        chart: {
          width: 300,
          type: "pie",
        },
        labels: ["Sudah TTD", "Belum TTD"],
        colors: ["#0045e6", "#e64500"],
        legend: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "center",
          floating: false,
          fontSize: "14px",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      id: this.$route.params.id,
      tanggal_rapat_direksi: '',
      tanggal_timeline: '',
      agenda_rapat: '',
      list_detail_rapat: '',
      pic: '',
      catatan: '',
      timeline: [],
      absensi_jabatan: [],
      sudah_ttd: '',
      belum_ttd: '',
      barcode_link: '',
      barcode_link_ori: ''
    };
  },
  mounted() {
    this.getNotulen();
  },
  methods: {
    getNotulen() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/monitoring/notulen/detail?id=" +
            self.id
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.list_data;
          if (response_data.code == 200) {
            self.tanggal_rapat_direksi = response_data_fix.data.nr_absensi_rapat_tanggal;
            self.pic = response_data_fix.data.nr_pic;
            self.catatan = response_data_fix.data.nr_catatan;
            self.tanggal_timeline = response_data_fix.data.nr_tanggal_awal_sirkuler;
            self.absensi_jabatan = response_data_fix.absenjabatan;
            
            var rapat_id = response_data_fix.data.nr_absensi_rapat_id;
            
            self.getBarcode();

            // GET ABSENSI AGENDA
            axios
              .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/administrasirapatagenda/absensiagenda?rapat_id="+rapat_id)
              .then((response) => {
              self.loadingTable = false;
              var response_data = response.data;
              if (response_data.code == 200) {
                self.list_detail_rapat = response_data.list_detail_rapat;
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: response_data.message,
                });
              }
            });

            // GET TIMELINE
            axios
              .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/monitoring/notulen/timeline?rapat_id="+self.id)
              .then((response) => {
              var response_data = response.data;
              if (response_data.code == 200) {
                self.timeline = response_data.list_data.data;
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: response_data.message,
                });
              }
            });

            // GET CHART
            axios
              .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/monitoring/notulen/chart?rapat_id="+self.id)
              .then((response) => {
              var response_data = response.data;
              if (response_data.code == 200) {
                self.series.push( response_data.jumlahSudahTtd );
                self.series.push( response_data.jumlahBelumTtd );
                self.sudah_ttd = response_data.jumlahSudahTtd;
                self.belum_ttd = response_data.jumlahBelumTtd;
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: response_data.message,
                });
              }
            });

            Swal.close();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    jarakWaktu(tglAkhir, tglAwal) {
        let dalamMs = Math.abs(new Date(tglAkhir) - new Date(tglAwal)) / 1000;

        // hari
        const hari = Math.floor(dalamMs / 86400);
        dalamMs -= hari * 86400;

        // jam
        const jam = Math.floor(dalamMs / 3600) % 24;
        dalamMs -= jam * 3600;

        // menit
        const menit = Math.floor(dalamMs / 60) % 60;
        dalamMs -= menit * 60;

        let jarak = '';

        if (hari > 0) {
            jarak += (hari === 1) ? `${hari} hari ` : `${hari} hari `;
        }

        if(jam > 0) {
            jarak += (jam === 0 || jam === 1) ? `${jam} jam ` : `${jam} jam `;
        }

        if(menit > 0) {
            jarak += (menit === 0 || jam === 1) ? `${menit} menit ` : `${menit} menit `; 
        }

        jarak += `${dalamMs} detik`; 

        return jarak;
    },

    getBarcode() {
      let self = this;
      var FormData = require("form-data");
      var data = new FormData();
      // var urlres = process.env.VUE_APP_URL;
      // urlres += self.route.path;
      var urlres = "/monitoring/notulen/ttd-notulen/" + self.id;
      data.append('link', urlres);
      // data.append("link", self.route.path);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/barcodehandle/barcode?id="+self.id,
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data; 
          //console.log(response_data.barcode);
          var barcode_url = process.env.VUE_APP_BACKEND_URL_VERSION;
          barcode_url += response_data.barcode;
          self.barcode_link = barcode_url;
          self.barcode_link_ori = response_data.barcode;
        })
      .catch((e) => {
        this.axiosCatchError = e.response.data.data;
      });
    },

    downloadHandle() {
      let self = this;
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_BACKEND_URL+"/monitoring/notulen/downloadqr?i="+self.id;
      link.setAttribute('target', '_blank');
      link.click();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h6>Detail Notulen Radisi</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <table
                  class="table mb-0 table-bordered table-condensed table-hover table-striped"
                >
                  <tbody>
                    <tr>
                      <td>Tanggal Rapat Direksi</td>
                      <td>{{ tanggal_rapat_direksi.split(' ')[0] }}</td>
                    </tr>
                    <tr>
                      <td>Agenda Rapat</td>
                      <td>
                        <div v-html="list_detail_rapat"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>PIC</td>
                      <td>{{ pic }}</td>
                    </tr>
                    <tr>
                      <td>Catatan</td>
                      <td>{{ catatan }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-4 text-center">
                <table>
                  <tbody>
                    <tr>
                      <div v-if="barcode_link">
                        <img v-bind:src="barcode_link" width="250"/> 
                      </div>
                      <div v-else>Barcode Tidak Tersedia</div>
                    </tr>
                  </tbody>
                  <div v-if="barcode_link">
                    <a v-on:click="downloadHandle" target="_blank" class="btn btn-success btn-sm mt-3">
                      Download QR Code
                    </a>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h6>Timeline Notulen Tanggal {{ tanggal_timeline.split(' ')[0] }}</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <table
                  class="table mb-0 table-bordered table-condensed table-hover table-striped"
                >
                  <thead>
                    <tr>
                      <td>Waktu Checkin</td>
                      <td>Waktu Checkout</td>
                      <td>Jabatan</td>
                      <td>SLA</td>
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="5">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="timeline == ''">
                        <td class="text-center" colspan="5">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in timeline"
                        :key="key_data"
                      >
                      <td class="text-center">
                        {{ row_data.tnr_waktu_masuk }}
                      </td>
                      <td class="text-center">
                        {{ row_data.tnr_waktu_keluar ? row_data.tnr_waktu_keluar : 'Not Recorded' }}
                      </td>
                      <td class="text-center">
                        {{ row_data.tnr_posisi_jabatan }}
                      </td>
                      <td class="text-center">
                        {{ row_data.tnr_waktu_keluar ? jarakWaktu(row_data.tnr_waktu_keluar, row_data.tnr_waktu_masuk) : '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Grafik -->
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <div class="card-header with-border">
            <h3 class="card-title">TTD Direksi Notulen</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                
              </div>
              <div class="col">
                <center>
                  <div id="chart">
                    <apexchart
                      type="pie"
                      :options="chartOptions"
                      :series="series"
                    ></apexchart>
                  </div>
                </center>
              </div>
              <div class="col">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header"></div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <table
                  class="table mb-0 table-bordered table-condensed table-hover table-striped"
                >
                  <thead>
                    <tr>
                      <td>No</td>
                      <td>Jabatan Direksi</td>
                      <td>Sudah / Belum</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="absensi_jabatan == ''">
                        <td class="text-center" colspan="5">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in absensi_jabatan"
                        :key="key_data"
                      >
                      <td class="text-center">
                        {{ key_data + 1 }}
                      </td>
                      <td>
                        {{ row_data.mpj_nama }}
                      </td>
                      <td>
                        <div v-if="row_data.tnr_posisi_jabatan">
                          <b>Sudah (V)</b>
                        </div>
                        <div v-else>
                          <b>Belum (X)</b>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
